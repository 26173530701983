import React from "react"
import { Container, Row, Col, InputGroup, Button } from "react-bootstrap"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { useReactiveVar } from "@apollo/client"

import "./contact-bot-map.css"
import CityInput from "./city-input"
import { selectedCityVar, setCityInput } from "../utils/reactive-vars"
import { getSelectedCity } from "../utils/city-manager"

const removeUrlProtocol = (url: string) => url.replace(/https?:\/\//, "")

/**
 * Component for displaying the contact information for cities that already use the bot.
 */
const ContactBotMap = ({ cityName = "" }) => {
  const selectedCity = cityName
    ? { input: cityName, city: getSelectedCity(cityName) }
    : useReactiveVar(selectedCityVar)

  const data = useStaticQuery(graphql`
    query {
      deutschlandKarte: file(relativePath: { eq: "Deutschland.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const clearInput = () => {
    setCityInput("")
  }

  return (
    <section className="bot-map padding-top-70" id="contact-bot-map">
      <div className="bot-map-content">
        <Container>
          <Row>
            <Col xs={12} md={6} className="mobile-top-fix contact-bot-form">
              <h4 data-aos="fade-up" data-aos-delay="200">
                {cityName ? "Nutzen" : "Finden"} Sie Ihren BürgerBot
              </h4>

              <h2 data-aos="fade-up" data-aos-delay="300">
                Kontaktdaten für {cityName ? cityName : "Ihre Stadt"}
              </h2>
              {!cityName && (
                <>
                  <div
                    className="form-group mt-4"
                    data-aos="fade-up"
                    data-aos-delay="400"
                  >
                    <InputGroup>
                      <CityInput id="city-input-map" />
                      <InputGroup.Append>
                        <Button onClick={clearInput}>
                          <i className="fa fa-times-circle"></i>
                        </Button>
                      </InputGroup.Append>
                    </InputGroup>
                  </div>
                  <noscript>
                    <Container>
                      <Row>
                        <Col xs={12}>
                          <p>
                            Für die Suche nach den Kontaktdaten Ihres BürgerBots
                            müssen Sie JavaScript in Ihrem Browser aktivieren.
                          </p>
                        </Col>
                      </Row>
                    </Container>
                  </noscript>
                </>
              )}
              {selectedCity.input && (
                <Container>
                  <Row>
                    <Col xs={12}>
                      <p data-aos="fade-up" data-aos-delay="400">
                        {selectedCity.city
                          ? "Suchen Sie im Messenger Ihrer Wahl nach dem angegebenen Namen bzw. der Nummer oder klicken Sie auf das entsprechende Symbol."
                          : "Diese Stadt nutzt den BürgerBot leider noch nicht."}
                      </p>
                    </Col>
                    {selectedCity.city &&
                      selectedCity.city.contact.map((contact, i) => (
                        <Col
                          key={i}
                          lg={6}
                          md={12}
                          sm={6}
                          xs={12}
                          data-aos="fade-up"
                          data-aos-delay="400"
                        >
                          <a
                            href={contact.url}
                            target="_blank"
                            className="bot-map-box"
                          >
                            <i className={`fa fa-${contact.iconType}`}></i>
                            <strong>{contact.typeName}</strong>
                            <span>{contact.name}</span>
                            <span>{removeUrlProtocol(contact.url)}</span>
                          </a>
                        </Col>
                      ))}
                  </Row>
                </Container>
              )}
            </Col>
            <Col lg={1} className="d-none d-lg-block"></Col>
            <Col
              xs={12}
              md={6}
              lg={5}
              className="align-self-center"
              data-scroll-reveal="enter left move 30px over 0.6s after 0.4s"
            >
              <div
                className="map-container"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                {selectedCity.city && (
                  <img
                    data-aos="fade-down"
                    data-aos-delay="200"
                    className="needle"
                    src="/images/Stecknadel.png"
                    alt="Stadt-Marker"
                    style={selectedCity.city.position}
                  />
                )}
                <Img fluid={data.deutschlandKarte.childImageSharp.fluid} />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  )
}

export default ContactBotMap
