import React from "react"
import { Link, useStaticQuery, graphql, PageProps } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import { Container, Row, Col } from "react-bootstrap"
import ContactBotMap from "../components/contact-bot-map"
import FooterImage from "../components/footer-image"

const HomePage = (props: PageProps<any, any>) => {
  const cityName = props.pageContext.name
  const data = useStaticQuery(graphql`
    query {
      features: file(relativePath: { eq: "features.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO
        title={`Der direkte Draht ${
          cityName ? `zur Stadt ${cityName}` : "zu deiner Stadt"
        }`}
      />
      <Hero cityName={cityName} />
      <section
        className="section padding-top-70 padding-bottom-100"
        data-aos="fade-up"
      >
        <Container>
          <Row>
            <Col
              xs={12}
              md={6}
              lg={5}
              className="align-self-center"
              data-scroll-reveal="enter left move 30px over 0.6s after 0.4s"
            >
              <img
                src="/images/use-case-x.gif"
                className="rounded img-fluid d-block mx-auto"
                alt="App"
              />
            </Col>
            <Col lg={1} className="d-none d-lg-block"></Col>
            <Col xs={12} md={6} className="align-self-center mobile-top-fix">
              <div className="left-heading">
                <h2 className="section-title">
                  Melden Sie Anliegen an{" "}
                  {cityName ? `die Stadt ${cityName}` : "Ihre Stadt"}
                </h2>
              </div>
              <div className="left-text">
                <p>
                  Der BürgerBot bietet die Möglichkeit rund um die Uhr Anliegen
                  an Ihre Stadtverwaltung zu melden. Sie benötigen dazu nur Ihr
                  Smartphone und eine von Ihnen verwendete Messenger-App.
                </p>
                <div data-aos="fade-up" data-aos-delay="100">
                  <Link to="/buerger" className="custom-btn btn mt-3 mr-3">
                    Mehr erfahren
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <ContactBotMap cityName={cityName} />
      <section className="padding-top-70" data-aos="fade-up">
        <Container>
          <Row>
            <Col
              xs={12}
              className="d-block d-md-none align-self-center mobile-bottom-fix-big"
              data-scroll-reveal="enter right move 30px over 0.6s after 0.4s"
            >
              <Img fluid={data.features.childImageSharp.fluid} />
            </Col>
            <Col xs={12} md={6} className="align-self-center mobile-bottom-fix">
              <div className="left-heading">
                <h2 className="section-title">
                  Mit dem BürgerBot in Richtung Smart City
                </h2>
              </div>
              <div className="left-text">
                <p>
                  Als Stadtverwaltung erhöhen Sie mit unserem Bot die
                  Bürgerbeteiligung und schaffen einen direkten
                  Kommunikationskanal zu Ihren Bürgerinnen und Bürgern. Durch
                  die verwendete künstliche Intelligenz und unser Ticketsystem
                  werden Anliegen automatisch kategorisiert und Bürgerinnen und
                  Bürgern kann direkt auf das Smartphone geantwortet werden.
                </p>
                <div data-aos="fade-up" data-aos-delay="100">
                  <Link to="/staedte" className="custom-btn btn mt-3 mr-3">
                    Infos zum Produkt
                  </Link>
                </div>
              </div>
            </Col>
            <Col lg={1} className="d-none d-lg-block"></Col>
            <Col
              md={6}
              lg={5}
              className="d-none d-md-block align-self-center mobile-bottom-fix-big"
              data-scroll-reveal="enter right move 30px over 0.6s after 0.4s"
            >
              <Img fluid={data.features.childImageSharp.fluid} />
            </Col>
          </Row>
        </Container>
      </section>
      <FooterImage />
    </Layout>
  )
}

export default HomePage
