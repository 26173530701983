import React from "react"
import { useReactiveVar } from "@apollo/client"

import "./hero.css"
import { Container, Row, Col } from "react-bootstrap"
import ArrowIcon from "./arrow-icon"
import { selectedCityVar } from "../utils/reactive-vars"

const Hero = ({ cityName = "" }) => {
  const selectedCity = useReactiveVar(selectedCityVar)
  const onBotContact = () => {
    if (!(selectedCity && selectedCity.city) && !cityName) {
      // hack to let the screen scroll at first to the section and then select the input field
      setTimeout(() => {
        const cityInput = document.getElementById("city-input-map")
        cityInput && cityInput.focus()
      }, 500)
    }
  }

  return (
    <section className="hero hero-bg d-flex justify-content-center align-items-center">
      <Container>
        <Row>
          <Col
            xs={12}
            md={6}
            className="d-flex flex-column justify-content-center align-items-center"
          >
            <div className="hero-text">
              <h1 className="text-white" data-aos="fade-up">
                BürgerBot — Der <strong>direkte Draht</strong>{" "}
                {cityName ? `zur Stadt ${cityName}` : "zu deiner Stadt"}
              </h1>

              <div
                data-aos="fade-up"
                data-aos-delay="100"
                className="hero-action pt-3"
              >
                <ArrowIcon className="hero-arrow-left mr-3" />
                <a
                  className="custom-btn btn-bg btn"
                  href="#contact-bot-map"
                  onClick={onBotContact}
                >
                  BürgerBot {cityName ? "nutzen" : "finden"}!
                </a>
                <ArrowIcon className="hero-arrow-right ml-3" />
              </div>
            </div>
          </Col>

          <Col xs={12} md={6}>
            <div className="hero-image" data-aos="fade-up" data-aos-delay="300">
              <img
                src="/images/stadt-screen.png"
                className="rounded img-fluid d-block mx-auto"
                alt="App"
              />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Hero
